class PDFViewer extends df.WebIFrame {
    constructor(sName, oParent) {
        super(sName, oParent);

        // Web properties
        this.prop(df.tString, 'psViewerUrl');
        this.psViewerUrl = 'Custom/pdfviewer/pdfjs-2.0.943-dist/web/viewer.html';
        this.prop(df.tBool, 'pbUseViewer');
        this.pbUseViewer = false;
    }

    afterRender() {
        super.afterRender();
        this.renderPDF();
    }

    renderPDF() {
        if (this.psUrl) {
            if (this.pbUseViewer && this.psViewerUrl) {
                const url = this.psUrl.startsWith('/') ? this.psUrl : `../../../../${this.psUrl}`;
                const src = `${this.psViewerUrl}?file=${encodeURIComponent(url)}`;
                super.set_psUrl(src);
            } else {
                super.set_psUrl(this.psUrl);
            }
        }
    }

    set_psUrl(sVal) {
        if (sVal !== this.psUrl) {
            this.psUrl = sVal;
            this.renderPDF();
        }
    }

    set_pbUseViewer(bVal) {
        if (bVal !== this.pbUseViewer) {
            this.pbUseViewer = bVal;
            this.renderPDF();
        }
    }
}

export default global.PDFViewer = PDFViewer;